import React from "react";
import logo from "../../assets/images/logo.png";

const Header = () => {
	return (
		<div style={{ padding: "4vh", height: "4vh" }}>
			<img src={logo} alt="logo" />
		</div>
	);
};

export default Header;

import React, { useEffect } from 'react';
import Signup from '../../components/Signup';
import './styles.css';

const EarlyAccess = () => {

  useEffect(() => {
    document.title = 'VYLD: The Play Social | Social app for digital natives';
  }, []);

  return (
    <div className='early-access__container'>
      <Signup />
    </div>
  );
}

export default EarlyAccess;

import React, { useEffect } from "react";
import "./styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Shade from "../../assets/images/shade.png";
import BoyAvatarImg from "../../assets/images/boy.png";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import img1 from "../../assets/images/career1.jpeg";
import img2 from "../../assets/images/career2.jpeg";

const Careers = () => {
  let navigate = useNavigate();

  useEffect(() => {
    document.title = 'Explore careers at VYLD | Join Our Team';
  }, []);

  return (
    <div className="careers__container bg_color">
      <div className="web-element ">
        <Header />
      </div>
      <div className="mobile-element">
        <img className="shade-image" src={Shade} />
        <div className="careers__back-icon-wrapper">
          <IoMdArrowRoundBack
            className="careers__back-icon"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>

      <div className="careers__title-container">
        <h1 className="title careers__title"> VYLD </h1>
        <h4 className="subtitle-wide"> Careers </h4>
      </div>

      <p className="careers__summary-text">
        We're not just building an app; we're crafting a revolution in social
        media. Want in?
      </p>

      <div className="wrapper ">
        <div className="card-container">
          <div className="careers__card careers__card-1">
            <h4 className="careers__card-title"> WHY VYLD? </h4>
            <p className="careers__summary careers__card-subtitle">
              🚀 Work on cutting-edge projects <br /> 💡 Make your ideas heard{" "}
              <br /> 🤝 Join a crew of thinkers and doers <br /> 🎉 Have loads
              of fun
            </p>
          </div>
          <div className="careers__card careers__card-2">
            <h4 className="careers__card-title"> THE PERKS </h4>
            <p className="careers__summary careers__card-subtitle">
              🕒 Flexi work hours <br />
              📚 Continuous learning <br />
              🌈 Vibrant work culture <br />
              📈 Growth opportunities
            </p>
          </div>
        </div>
      </div>

      <div className="careers__card-3__container">
        <div className="careers__card careers__card-3">
          <div>
            <img className="boy-image" src={BoyAvatarImg} alt="VYLD app avatars" />
          </div>
          <div className="careers__card-3-text">
            <h4 className="careers__card-title"> Ready to join? </h4>
            <p className="careers__reachout-text">Reach out to us at</p>
            <p className="careers__summary careers__card-subtitle">
              <a
                className="email-link"
                href="mailto:careers@vyld.io"
                target="_blank"
              >
                careers@vyld.io
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Live Now */}
      <div className="live_cont">
        <div className="inner_cont">
           <div className="careers__title-container">
           <h1 className="title careers__title"> Live Now!</h1>
           </div>
           <div className="img_cont">
             <a  href="https://www.linkedin.com/jobs/view/3853457645" target="_blank" >
              <img className="live_img1" src={img1} alt="VYLD app social media and community manager jobs"/>
             </a>
             <a  href="https://www.linkedin.com/jobs/view/3853470248?original_referer=" target="_blank" >
              <img className="live_img2" src={img2} alt="VYLD app digital marketing jobs"/>
             </a>
           </div>
        </div>

        <div className="extra_cont bg_black"></div>
        
      </div>

     

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Careers;

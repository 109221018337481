import React, { useEffect } from "react";
import "./styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Shade from "../../assets/images/shade.png";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const Tribe = () => {
  let navigate = useNavigate();

  useEffect(() => {
    document.title = 'VYLD Tribe: Videos, Blogs, Contests & More';
  }, []);

  return (
    <div className="tribe__container">
      <div className="web-element">
        <Header />
      </div>
      <div className="mobile-element">
        <img className="shade-image" src={Shade} />
        <div className="tribe__back-icon-wrapper">
          <IoMdArrowRoundBack
            className="tribe__back-icon"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>

      <div className="tribe__title-container">
        <h1 className="title tribe__title"> VYLD </h1>
        <h4 className="subtitle-wide"> TRIBE </h4>
      </div>

      <div className="tribe__coming-soon-text">
        <h1 className="title tribe__coming-text"> Coming Soon....</h1>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Tribe;

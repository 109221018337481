import React, { useEffect } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";

const TnC = () => {
	let navigate = useNavigate();

	useEffect(() => {
		document.title = "VYLD Terms and Conditions";
	}, []);

	return (
		<>
			<div className="tncMain">
				<h1 className="header">Terms and Conditions</h1>
				<p className="content last-updated">Last Updated: August 21, 2023</p>
				<p className="content">
					Welcome to vyld.io! These terms and conditions govern the use of our
					website vyld.io and our social media app. By utilizing this feature,
					you agree to comply with these terms and conditions. Please review
					this document carefully, especially if you are a minor, before
					proceeding.
				</p>

				<h2 className="header">1. Acceptance of Terms</h2>
				<p className="content">
					By signing up on vyld.io, you acknowledge and agree to be bound by
					these terms and conditions. If you are under the age of 18, you must
					obtain consent from a parent or legal guardian before using this
					feature.
				</p>

				<h2 className="header">2. Account Creation and Authentication</h2>
				<p className="content">
					When using the "Sign in with Google" feature, you authorise vyld.io to
					access your Google account information, including your basic profile
					details and permissions granted to us by Google.
				</p>
				<p className="content">
					Minors are required to seek parental or legal guardian consent before
					utilising the "Sign in with Google" feature.
				</p>
				<p className="content">
					It is your responsibility to ensure that the information provided
					during the authentication process is accurate and complete.
				</p>
				<p className="content">
					Safeguarding your Google account and vyld.io account credentials is
					crucial to prevent unauthorised access.
				</p>

				<h2 className="header">3. User Conduct</h2>
				<p className="content">
					You agree not to misuse or abuse the "Sign in with Google" feature,
					including attempting to gain unauthorised access to any user accounts
					or systems.
				</p>
				<p className="content">
					Both minors and adults must adhere to applicable laws and regulations
					when using vyld.io and the "Sign in with Google" feature.
				</p>

				<h2 className="header">4. Data Privacy</h2>
				<p className="content">
					The information collected from your Google account via the "Sign in
					with Google" feature is governed by our{" "}
					<a href="/privacy-policy" className="link">
						Privacy Policy
					</a>
					, which outlines data collection, usage, and protection practices.
				</p>
				<p className="content">
					Minors should seek parental or legal guardian consent before using the
					"Sign in with Google" feature and sharing personal information.
				</p>

				<h2 className="header">5. Changes and Termination</h2>
				<p className="content">
					We reserve the right to modify, suspend, or terminate the sign-up
					process and the "Sign in with Google" feature or your access to it,
					without prior notice.
				</p>
				<p className="content">
					Users of all ages can delete the access given to vyld.io through their
					Google account via the security settings on the Google account.
				</p>

				<h2 className="header">6. Disclaimer of Liability</h2>
				<p className="content">
					The "Sign in with Google" feature is provided on an "as-is" and
					"as-available" basis. We do not guarantee uninterrupted or error-free
					operation.
				</p>
				<p className="content">
					We are not liable for any losses, damages, or consequences resulting
					from your use of the "Sign in with Google" feature.
				</p>

				<h2 className="header">7. Intellectual Property</h2>
				<p className="content">
					All intellectual property rights pertaining to vyld.io, and its
					content belong to vyld.io and its licensors.
				</p>

				<h2 className="header">8. Governing Law and Dispute Resolution</h2>
				<p className="content">
					These terms and conditions are governed by the laws of India. Any
					disputes arising from or related to these terms are subject to the
					exclusive jurisdiction of the courts in India.
				</p>

				<h2 className="header">9. Contact Us</h2>
				<p className="content">
					For queries or concerns about these terms and conditions or the "Sign
					in with Google" feature, please reach out to us at{" "}
					<a href="mailto:social@vyld.io" className="link">
						social@vyld.io
					</a>
					.
				</p>
			</div>
		</>
	);
};

export default TnC;

import React, { useState } from "react";
import "./styles.css";
import VyldLogoWhite from "../../assets/images/vyld-logo-white.png";
import Menu from "../../assets/images/menu-icon-img.png";
import Shade from "../../assets/images/shade.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { IoCloseSharp } from "react-icons/io5";

const Header = () => {
  const location = useLocation();
  const list = [
    {
      id: 0,
      item: "VYLD Tribe",
      link: "/vyld-tribe",
    },
    {
      id: 1,
      item: "About us",
      link: "/about-us",
    },
    {
      id: 2,
      item: "News",
      link: "/news",
    },
    {
      id: 3,
      item: "Careers",
      link: "/careers",
    },
    {
      id: 4,
      item: "Contact",
      link: "mailto:info@vyld.io",
    },
  ];
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleClick = (e) => {
    e.preventDefault();
    let elmt = list.filter((item) => {
      let extractedId = e.target.id.charAt(e.target.id.length - 1);
      return item.id === Number(extractedId);
    });

    if (elmt) {
      if (elmt[0].id == 4) {
        window.location.href = elmt[0].link;
      } else {
        navigate(elmt[0].link);
      }
    }
  };

  return (
    <div className="header__container">
      <img className="shade-image" src={Shade} />

      <a href="/">
        <img className="header__logo" src={VyldLogoWhite} alt="VYLD app logo with text PNG" />
      </a>
      <a className="header__menu-button" onClick={handleOpenDialog}>
        <img className="header__menu-icon" src={Menu} />
      </a>

      {isDialogOpen && (
        <div className="menu-backdrop">
          <div className="menu-dialog-container">
            <div className="menu-card-icon">
              <IoCloseSharp
                className="close-icon"
                onClick={handleCloseDialog}
              />
            </div>

            <div className="header__list">
              {list.map((e) => (
                <button
                  className="header__list-item"
                  id={`menu-item-${e.id}`}
                  key={e.id}
                  onClick={handleClick}
                >
                  {" "}
                  {e.item}{" "}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="header__list web-element">
        {list.map((e) => (
          <Link
            to={e.link}
            className={
              location.pathname === e.link
                ? "active header__list-item"
                : "header__list-item"
            }
            id={`menu-item-${e.id}`}
            key={e.id}
            onClick={handleClick}
          >
            {e.item}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import Logo from "../../assets/images/logo.png";
import "./styles.css";

const Footer = () => {
  const list_one = [
    {
      id: 0,
      item: "VYLD Tribe",
      link: "/vyld-tribe",
    },
    {
      id: 1,
      item: "About us",
      link: "/about-us",
    },
    {
      id: 2,
      item: "News",
      link: "/news",
    },
    {
      id: 3,
      item: "Careers",
      link: "/careers",
    },
    {
      id: 4,
      item: "Email Us",
      link: "mailto:info@vyld.io",
    },
  ];

  const list_two = [
    {
      id: 5,
      item: "Privacy Policy",
      link: "https://vyld.io/privacy-policy",
    },
    {
      id: 6,
      item: "Terms of Service",
      link: "https://vyld.io/tnc",
    },
  ];

  return (
    <div className="footer__container">
      <img
        className="footer__image"
        src={Logo}
        alt="VYLD app logo white background PNG"
      />
      <div className="footer__list list-one">
        {list_one.map((e) => (
          <a className="footer__list-item" key={e.id} href={e.link}>
            {" "}
            {e.item}{" "}
          </a>
        ))}
      </div>
      <div className="footer__list list-two">
        {list_two.map((e) => (
          <a className="footer__list-item" key={e.id} href={e.link}>
            {" "}
            {e.item}{" "}
          </a>
        ))}
      </div>
      <p className="footer__copyright-text">
        {" "}
        Copyright Soulgenix Technologies Pvt. Ltd. @ 2024{" "}
      </p>
    </div>
  );
};

export default Footer;

import React, { useEffect } from "react";
import "./styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Shade from "../../assets/images/shade.png";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const About = () => {
  let navigate = useNavigate();

  useEffect(() => {
    document.title = "About VYLD: Our story, mission, and vision";
  }, []);

  return (
    <div className="about__container">
      <div className="web-element">
        <Header />
      </div>
      <div className="mobile-element">
        <img className="shade-image" src={Shade} />
        <div className="about__back-icon-wrapper">
          <IoMdArrowRoundBack
            className="about__back-icon"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>

      <div className="about__title-container">
        <h1 className="title about__title"> VYLD </h1>
        <h4 className="subtitle-wide"> Story </h4>
      </div>

      <p className="about__summary-text">
        Coming soon to your smartphones, your go-to social space, built
        exclusively for you.
      </p>
      <div className="wrapper">
        <div className="card-container">
          <div className="about__card about__card-1">
            <h4 className="about__card-title"> Our Vibes </h4>
            <p className="about__summary about__card-subtitle">
              In a world full of 'been there, done that,' we're here to bring
              the fresh, the fearless, and the fantastically different.
            </p>
          </div>
          <div className="about__card about__card-2">
            <h4 className="about__card-title"> Our MISSION </h4>
            <p className="about__summary about__card-subtitle">
              Together, let's break the norms, build connections, and spark
              memories.
            </p>
          </div>
          <div className="about__card about__card-3">
            <h4 className="about__card-title"> Our ETHOS </h4>
            <p className="about__summary about__card-subtitle">
              We're all about positive vibes, real connections, and playful
              experiences. Your privacy, your voice, your digital well-being -
              they're not just priorities, they're our ethos.
            </p>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default About;

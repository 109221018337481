import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles.css";
import config from "../../config/app-config";

const OtpDialog = ({ onClose, phoneNumber }) => {
  const BASE_URL = config.apiUrl;

  const countdownTimer = 30; // 30 seconds
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(countdownTimer);
  const [resendDisabled, setResendDisabled] = useState(true);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      setResendDisabled(false);
    }
  }, [timer]);

  const handleInputChange = (index, value) => {
    const newOtp = otp.split("");
    newOtp[index] = value;
    setOtp(newOtp.join(""));

    if (index < 3 && value !== "") {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleKeyPress = (element, index) => {
    if (element.key === "Delete" || element.key === "Backspace") {
      const nextInput = document.getElementById(`otp-input-${index - 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleResend = async () => {
    setTimer(countdownTimer);
    setResendDisabled(true);
    setOtp("");
    await axios
      .post(BASE_URL + "/auth/otp", {
        phoneNumber: phoneNumber,
      })
      .then()
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await axios
      .post(BASE_URL + "/auth/otp/verify", {
        phoneNumber: phoneNumber,
        otp: otp,
      })
      .then((res) => {
        if (res.data.status) {
          handleClose();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClose = () => {
    // Call the onClose function passed from parent component
    onClose(true);
  };

  return (
    <div className="otp-dialog__container">
      <h2 className="otp-dialog__title"> OTP Verification </h2>
      <p className="otp-dialog__subtitle">
        {" "}
        Enter the OTP sent to {phoneNumber}{" "}
      </p>

      <form onSubmit={handleSubmit}>
        <div className="otp-dialog__input-group">
          {[0, 1, 2, 3].map((index) => (
            <input
              key={index}
              id={`otp-input-${index}`}
              type="number"
              maxLength={1}
              value={otp[index] || ""}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyUp={(e) => handleKeyPress(e, index)}
            />
          ))}
        </div>
        <div className="otp-dialog__timer">
          {timer > 0 ? (
            `00:${timer}`
          ) : (
            <button onClick={handleResend} disabled={resendDisabled}>
              Resend
            </button>
          )}
        </div>{" "}
        <input
          className={
            otp.length === 4 && timer > 0
              ? "register-button__enabled"
              : "register-button__disabled"
          }
          type="submit"
          value="Sign up"
          disabled={otp.length !== 4 || timer === 0}
        />
      </form>
    </div>
  );
};

export default OtpDialog;

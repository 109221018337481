import React from "react";
import "./styles.css";

import SnowImg from "../../assets/images/snow.png";

import SignupFloatingImg1 from "../../assets/images/signup-floating-image1.png";
import SignupFloatingImg2 from "../../assets/images/signup-floating-image2.png";
import SignupFloatingImg3 from "../../assets/images/signup-floating-image3.png";
import SignupFloatingImg4 from "../../assets/images/signup-floating-image4.png";
import SignupFloatingImg6 from "../../assets/images/soft-star.png";
import AlphaSquadText from "../../assets/images/aplha-squad-text.png";
import SignupFloatingImg10 from "../../assets/images/lightning.png";

import CloudBg from "../../assets/images/cloud-bg.png";
import PinkBg from "../../assets/images/pink-scribble-cloud.png";
import BetaFeat from "../../assets/images/beta-trail.png";
import PlayFeat from "../../assets/images/play.png";
import InteractFeat from "../../assets/images/interact.png";
import ArtsFeat from "../../assets/images/arts.png";
import EyeIcon from "../../assets/images/eye-icon.png";

import MysteryBox from "../../assets/images/mystry-box.png";
import BraggingRightBox from "../../assets/images/bragging-rights.png";
import GoodiesBox from "../../assets/images/goodies.png";
import InvitesBox from "../../assets/images/invites.png";
import EarlyAccessBox from "../../assets/images/early-access-to-app.png";

import InsightBottomMob1 from "../../assets/images/insight-bottom-img1-mob.png";
import InsightBottomDesk1 from "../../assets/images/insight-bottom-img1-desk.png";
import InsightBottom2 from "../../assets/images/insight-bottom-img2.png";

const SuccessAlphaSquad = () => {
  return (
    <div className="success__container">
      
      <img className="snow-img" src={SnowImg} />

      <div className="success__desc__container">
        <img className="sfloating-img-4" src={SignupFloatingImg4} alt="VYLD Alpha Squad member" />

        <div className="success__desc__text__container">
          <img className="ssfloating-img-9" src={CloudBg} />

          <h4 className="success__desc__title"> Thank You </h4>

          <p className="success__desc__text">
            {" "}
            We’ll be talking to you <br /> real soon!{" "}
          </p>
        </div>

        <img className="sfloating-img-3" src={SignupFloatingImg3} alt="VYLD app Gen Z user from Latin America" />
      </div>

      <div className="success__title__container">
        <img className="sfloating-img-7" src={SignupFloatingImg2} alt="VYLD app Gen Z user from Southeast Asia" />

        <div className="success__title__text">
          <h4 className="success__title__subtitle"> So what's the </h4>
          <img className="sfloating-img-5" src={AlphaSquadText} />
          <p className="success__title__desc">
            {" "}
            An exclusive club that will help build a vibrant, new social
            platform that resonates with you and your friends - minus your
            uncle.{" "}
          </p>
        </div>
        <img className="sfloating-img-6" src={SignupFloatingImg6} />
        <img className="sfloating-img-10" src={SignupFloatingImg10} />
        <img className="sfloating-img-8" src={SignupFloatingImg1} alt="VYLD app Gen Z user from India" />
      </div>

      <div className="success__features__container">
        <h4 className="success__features_title">
          What you will DO as the <br /> VYLD Alpha Squad
        </h4>

        <div className="success__features__list__container">
          <img src={SignupFloatingImg10} className="sflighting-icon" />
          <img className="sfpink-bg" src={PinkBg} />
          <div className="success__features__list">
            <img className="sf-image" src={BetaFeat} alt="A hot air balloon to signify early access to the VYLD app" />
            <img className="sf-image" src={PlayFeat} alt="A joystick to showcase how Alpha Squad members have a say in building VYLD" />
            <img className="sf-image" src={InteractFeat} alt="A group of Gen Z having fun through the VYLD Alpha Squad" />
            <img className="sf-image" src={ArtsFeat} alt="A solved Rubik’s cube to denote how Gen Z’s skills and knowledge can help build VYLD" />
          </div>
          <img src={EyeIcon} className="sfeye-icon" />
        </div>
      </div>

      <div className="success__bg">
        <div className="success__bonus__container">
          <h4 className="success__features_title">
            What you will GET as the <br /> VYLD Alpha Squad{" "}
          </h4>

          <div className="success__bonus__list__container">
            <div className="success__bonus__list-1">
              <img className="sb-image sb-image-1" src={MysteryBox} alt="Mystery box surprise gift | PNG | VYLD Alpha Squad mystery boxes" />
              <img className="sb-image sb-image-5" src={EarlyAccessBox} alt="Lock | PNG | VYLD Alpha Squad early access" />
            </div>

            <img className="sb-image sf-image-3" src={GoodiesBox} alt="Open goodies box | PNG | VYLD Alpha Squad goodies on sign-up" />

            <div className="success__bonus__list-2">
              <img className="sb-image sb-image-4" src={InvitesBox} alt="Envelope image | PNG | VYLD Alpha Squad invites for friends" />
              <img className="sb-image sb-image-2" src={BraggingRightBox} alt="Trophy image | PNG | VYLD Alpha Squad leaderboard" />
            </div>
          </div>
        </div>

        <div className="success__footer-content">
          <p className="success__footer-text">
            Want to get started with simple things you <br /> can do right away?
          </p>

          <a className="success__footer-btn" href="/insights">
            {" "}
            Get Started{" "}
          </a>
        </div>

        <div className="success__footer">
          <div>
            <img
              className="success__footer_img1 success__footer__mob-img-1"
              src={InsightBottomMob1}
            />
            <img
              className="success__footer_img1  success__footer__desk-img-1"
              src={InsightBottomDesk1}
            />
          </div>
          <div>
            <img className="success__footer_img2" src={InsightBottom2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessAlphaSquad;

import React, { useEffect } from "react";
import "./styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Shade from "../../assets/images/shade.png";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const News = () => {
  let navigate = useNavigate();

  useEffect(() => {
    document.title = 'VYLD News: Latest updates and insights from our world';
  }, []);

  return (
    <div className="news__container">
      <div className="web-element">
        <Header />
      </div>
      <div className="mobile-element">
        <img className="shade-image" src={Shade} />
        <div className="news__back-icon-wrapper">
          <IoMdArrowRoundBack
            className="news__back-icon"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>

      <div className="news__title-container">
        <h1 className="title news__title"> VYLD </h1>
        <h4 className="subtitle-wide"> News </h4>
      </div>

      <p className="news__desc-text">
        For media enquiries, <br /> write to us at{" "}
        <a className="email-link" href="mailto:press@vyld.io" target="_blank">
          press@vyld.io
        </a>
      </p>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default News;

import React, { useEffect } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
	let navigate = useNavigate();

	useEffect(() => {
		document.title = "VYLD Privacy Policy";
	}, []);

	return (
		<>
			<div className="privacyPolicyMain">
				<h1 className="header">Privacy Policy</h1>

				<p className="content">
					<strong>Effective date:</strong> August 21, 2023
				</p>

				<p className="content">
					Welcome to vyld.io! This privacy policy outlines how we collect, use,
					disclose, and safeguard personal information — including that of
					minors — on our website and/or social media app. Please carefully
					review this policy, particularly if you are a minor, to comprehend our
					practices and your rights.
				</p>

				<p className="content">
					By using the "Sign in with Google" feature on vyld.io, you agree to
					the collection and processing of your information, including that of
					minors, as detailed in this policy.
				</p>

				<h2 className="header">1. Information We Collect</h2>

				<p className="content">
					When using the "Sign in with Google" feature on vyld.io, we may
					collect the following information from your Google account:
				</p>

				<ul className="content">
					<li>
						<strong>Personal Information:</strong> We may collect your name,
						email address, and other contact details when you create an account
						or update your profile.
					</li>
					<li>
						<strong>Usage Data:</strong> We gather information about how you use
						our app, such as your interactions, posts, and the content you view.
					</li>
					<li>
						<strong>Device Information:</strong> We collect device-specific
						information like your IP address, device type, operating system, and
						browser details.
					</li>
				</ul>

				<h2 className="header">2. How We Use Your Information</h2>

				<p className="content">
					We employ the information collected from your account to:
				</p>

				<ul className="content">
					<li>establish and manage your vyld.io account.</li>
					<li>personalise your experience on our platform.</li>
					<li>facilitate seamless sign-in and authentication.</li>
					<li>
						communicate with you, including sending essential updates and
						notifications related to your vyld.io account.
					</li>
				</ul>

				<h2 className="header">3. Data Sharing and Disclosure</h2>

				<p className="content">
					We do not share personal information obtained via the "Sign in with
					Google" feature with third parties without explicit consent. However,
					please be aware that your actions and content on vyld.io may be
					visible to other users in line with our app's features and settings.
				</p>

				<ul className="content">
					<li>
						<strong>Third Parties:</strong> We may share your information with
						trusted third-party partners to deliver certain services, like
						analytics or technical support.
					</li>
					<li>
						<strong>Legal Obligations:</strong> We may disclose your information
						if required by law, court order, or to protect our rights and
						safety.
					</li>
				</ul>

				<h2 className="header">4. Data Security</h2>

				<p className="content">
					Safeguarding your personal information is a priority. We implement
					industry-standard security measures to prevent unauthorised access,
					alteration, disclosure, or destruction of data.
				</p>

				<h2 className="header">5. Children’s Privacy</h2>

				<p className="content">
					Our app is not intended for children under the age of 13. We do not
					knowingly collect personal information from them.
				</p>

				<h2 className="header">6. Rights and Choices</h2>

				<p className="content">
					You can review and update your account information by accessing your
					vyld.io account settings.
				</p>

				<p className="content">
					Minors under 18 years old should obtain parental or legal guardian
					consent before using the "Sign in with Google" feature. Minors'
					parents or legal guardians can request access, correction, deletion,
					or restriction of their child's data. If you have concerns about data
					processing, you can lodge a complaint with a supervisory authority.
				</p>

				<h2 className="header">7. Changes to this Privacy Policy</h2>

				<p className="content">
					This privacy policy may be updated to reflect changes in practices or
					for legal reasons. Significant changes will be communicated through
					our website or other channels.
				</p>

				<h2 className="header">8. Contact Us</h2>

				<p className="content">
					For questions, concerns, or inquiries regarding this privacy policy or
					personal information, contact us at{" "}
					<a href="mailto:social@vyld.io">social@vyld.io</a>.
				</p>
			</div>
		</>
	);
};

export default PrivacyPolicy;

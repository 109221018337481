import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import OtpDialog from "../Otp";
import config from "../../config/app-config";

import SignupFloatingImage1 from "../../assets/images/floating-boy-img.png";
import SignupFoatingImage2 from "../../assets/images/floating-girl-img2.png";
import SignupFoatingImage3 from "../../assets/images/floating-girl-img.png";

const Signup = () => {
  const BASE_URL = config.apiUrl;
  const navigate = useNavigate();
  const initialState = {
    name: "",
    email: "",
    phoneNumer: "",
    instaId: "",
    errors: {},
    loading: false,
  };
  const [formData, setFormData] = useState(initialState);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (val) => {
    setIsDialogOpen(false);
    if (val) {
      navigate("/success");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      errors: {
        ...formData.errors,
        [name]: "",
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    // Name validation
    if (!formData.name.trim()) {
      errors.name = "Please enter a valid name";
    }

    // Email validation
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Please enter a valid email id";
    }

    // Phone number validation
    if (!formData.phoneNumer.trim()) {
      errors.phoneNumer = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phoneNumer)) {
      errors.phoneNumer = "Please enter a valid phone number";
    }

    if (Object.keys(errors).length === 0) {
      // No errors, submit the form and sending data to the server
      setFormData({
        ...formData,
      });
      await axios
        .post(BASE_URL + "/user/earlyAccess", {
          name: formData.name,
          email: formData.email,
          phoneNumber: formData.phoneNumer,
          instaId: formData.instaId,
        })
        .then(async (res) => {
          if (res.data.status) {
            await axios
              .post(BASE_URL + "/auth/otp", {
                phoneNumber: formData.phoneNumer,
              })
              .then((res) => {
                if (res.data.status) {
                  handleOpenDialog();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setFormData({ ...formData, errors });
    }
  };

  return (
    <div className="signup__container">
      <p className={!formData.loading ? "display-none" : "display"}>
        {" "}
        Loading please wait...{" "}
      </p>
      <img
        className="signup__image-3"
        src={SignupFoatingImage3}
        alt="VYLD app Gen Z user from Bangalore"
      />
      <div className="signup-header__container">
        <img
          className="signup__image-1"
          src={SignupFloatingImage1}
          alt="VYLD app Gen Z user from Mumbai"
        />
        <div>
          <h4 className="signup-header__subtitle"> Sign Up for </h4>
          <h1 className="signup-header__title">Early Access</h1>
        </div>
        <img
          className="signup__image-2"
          src={SignupFoatingImage2}
          alt="VYLD app Gen Z user from Delhi"
        />
      </div>
      <div className="signup-form__container">
        <form className="signup-form" onSubmit={handleSubmit}>
          <div className="signup-form__name-wrapper">
            <span
              className={
                formData?.name?.length > 0 ? "display-none" : "required"
              }
            >
              *
            </span>
            <input
              className="name-input"
              placeholder="NAME"
              type="text"
              name="name"
              required
              value={formData.name}
              onChange={handleChange}
            />
            {formData.errors.name && (
              <div className="error-text-wrapper">
                <span className="error-text">{formData.errors.name}</span>
              </div>
            )}
          </div>

          <div className="signup-form__email-wrapper">
            <span
              className={
                formData?.email?.length > 0 ? "display-none" : "required"
              }
            >
              *
            </span>
            <input
              className="email-input"
              placeholder="EMAIL ID"
              type="text"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
            />
            {formData.errors.email && (
              <div className="error-text-wrapper">
                <span className="error-text">{formData.errors.email}</span>
              </div>
            )}
          </div>
          <div className="signup-form__phone-wrapper">
            <span
              className={
                formData?.phoneNumer?.length > 0 ? "display-none" : "required"
              }
            >
              *
            </span>
            <input
              className="phone-input"
              placeholder="PHONE"
              type="number"
              name="phoneNumer"
              required
              value={formData.phoneNumer}
              onChange={handleChange}
            />
            {formData.errors.phoneNumer && (
              <div className="error-text-wrapper">
                <span className="error-text">{formData.errors.phoneNumer}</span>
              </div>
            )}
          </div>

          <input
            className="instaid-input"
            placeholder="INSTA ID"
            type="text"
            name="instaId"
            value={formData.instaId}
            onChange={handleChange}
          />
          <input
            className={
              formData.name && formData.email && formData.phoneNumer
                ? "otp-button__enabled"
                : "otp-button__disabled"
            }
            type="submit"
            value="Get OTP"
            disabled={
              formData.loading ||
              !formData.name ||
              !formData.email ||
              !formData.phoneNumer
            }
          />
        </form>
      </div>

      {isDialogOpen && (
        <div className="backdrop">
          <div className="dialog-container">
            <OtpDialog
              phoneNumber={formData.phoneNumer}
              onClose={handleCloseDialog}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;

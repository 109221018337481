import { useState, useEffect, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { mobileModel } from "react-device-detect";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

  body {
    font-family: 'Montserrat'
  }
`;

const link = document.createElement("link");
link.rel = "stylesheet";
link.href =
	"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css";
document.head.appendChild(link);

export default function Button1(props) {
	const [inputValue, setInputValue] = useState("");
	const [number, setNumber] = useState("");
	const [apiResponse, setApiResponse] = useState(null);
	const [type, setType] = useState(null);
	const [meta, setMeta] = useState(null);
	const [selectedType, setSelectType] = useState("");
	const btnClick = useRef();

	const handleButtonClick = async () => {
		if (type == "WEB" && selectedType == "") alert("Select platform");
		try {
			const config = {
				method: "POST",
				credentials: "include",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: new URLSearchParams({
					phoneNumber: number,
					deviceType: type,
					meta: meta,
				}),
			};

			try {
				const response = await fetch(
					"https://vb-api-lp.vyld.io/forms/registration",
					config
				);
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				const { data } = await response.json();

				if (type == "ANDROID")
					window.location.href = "https://wyb.social/on-list";
				else if (type == "IOS")
					window.location.href =
						"https://apps.apple.com/app/wyb-the-play-social/id6596755967";

				if (type == "WEB") {
					if (selectedType == "android")
						window.location.href = "https://wyb.social/on-list";
					else
						window.location.href =
							"https://apps.apple.com/app/wyb-the-play-social/id6596755967";
				}
			} catch (error) {
				console.error("Error:", error);
			}
		} catch (error) {
			console.error(
				"There has been a problem with your fetch operation:",
				error
			);
		}
	};

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
	};

	const getData = async () => {
		try {
			const config = {
				method: "GET",
				credentials: "include",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/x-www-form-urlencoded",
				},
			};

			try {
				const response = await fetch(
					"https://vb-api-lp.vyld.io/forms/checkDeviceType",
					config
				);
				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}
				const { data } = await response.json();
				setType(data?.deviceType);
				console.log("deviceType");
				if (data?.deviceType == "IOS") {
					window.location.href =
						"https://apps.apple.com/app/wyb-the-play-social/id6596755967";
					setTimeout(() => {
						window.location.href = "https://wyb.social";
					}, 1000);
				} else if (data?.deviceType == "ANDROID") {
					window.location.href =
						"https://play.google.com/store/apps/details?id=com.soulgenix.android";
					setTimeout(() => {
						window.location.href = "https://wyb.social";
					}, 1000);
				} else {
					window.location.href = "https://wyb.social/pre-registration";
				}

				let a = data?.meta;
				a = JSON.parse(a);

				a.mobileModel = mobileModel;

				a = JSON.stringify(a);
				setMeta(a);
			} catch (error) {
				console.error("Error:", error);
			}
		} catch (err) {
			console.log("Error ", err);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	const [phoneNumber, setPhoneNumber] = useState(Array(10).fill(""));
	const inputRefs = useRef([]);

	const handleChange = (index, value) => {
		if (value.match(/^[0-9]$/)) {
			const newPhoneNumber = [...phoneNumber];
			newPhoneNumber[index] = value;
			setPhoneNumber(newPhoneNumber);

			if (index < phoneNumber.length - 1) {
				inputRefs.current[index + 1].focus();
			}
		}
	};

	const handleKeyDown = (index, event) => {
		if (event.key === "Backspace") {
			if (index > 0) {
				inputRefs.current[index - 1].focus();
			}
			const newPhoneNumber = [...phoneNumber];
			newPhoneNumber[index] = "";
			setPhoneNumber(newPhoneNumber);
		} else if (event.key === "ArrowRight") {
			if (index < phoneNumber.length - 1) {
				inputRefs.current[index + 1].focus();
			}
		} else if (event.key === "ArrowLeft") {
			if (index > 0) {
				inputRefs.current[index - 1].focus();
			}
		}
	};

	const handleFocus = (index) => {
		inputRefs.current[index].setSelectionRange(0, 1);
	};

	useEffect(() => {
		if (inputRefs.current[0]) {
			inputRefs.current[0].focus();
		}
	}, []);

	const buttonRef = useRef(null);

	useEffect(() => {
		if (buttonRef.current) {
			buttonRef.current.style.setProperty("background", "#C6FF00", "important");
		}
	}, []);

	const inputRef = useRef(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [inputRef]);

	return (
		// <Container>
		//     <GlobalStyle />
		//     <SubContainer>
		//         <InputCont>
		//             <InputContainer>
		//                 <CountryCode>+91</CountryCode>
		//                 <NumberInput
		//                     type="number"
		//                     ref={inputRef}
		//                     value={number}
		//                     maxLength={10}
		//                     onChange={(e) => {
		//                         if (e.target.value.length == 11) return
		//                         else setNumber(e.target.value)
		//                     }}
		//                     disabled={type == "IOS" ? true : false}
		//                 />
		//             </InputContainer>
		//         </InputCont>

		//         {type == "WEB" && (
		//             <RadioContainer>
		//                 <RadioOption>
		//                     <input
		//                         type="radio"
		//                         id="android"
		//                         name="type"
		//                         value="android"
		//                         checked={selectedType === "android"}
		//                         onChange={(e) => setSelectType(e.target.value)}
		//                     />
		//                     <label htmlFor="android">
		//                         <i className="fab fa-google-play"></i> Google
		//                         Play
		//                     </label>
		//                 </RadioOption>
		//                 <RadioOption>
		//                     <input
		//                         type="radio"
		//                         id="ios"
		//                         name="type"
		//                         value="ios"
		//                         checked={selectedType === "ios"}
		//                         onChange={(e) => setSelectType(e.target.value)}
		//                     />
		//                     <label htmlFor="ios">
		//                         <i className="fab fa-apple"></i> Apple Store
		//                     </label>
		//                 </RadioOption>
		//             </RadioContainer>
		//         )}

		//         <SubmitCon>
		//             <SubmitButton onClick={handleButtonClick} ref={buttonRef}>
		//                 {type == "ANDROID" ? (
		//                     <>
		//                         <i className="fab fa-google-play"></i> Submit &
		//                         Pre Register
		//                     </>
		//                 ) : (
		//                     "Submit"
		//                 )}
		//             </SubmitButton>
		//         </SubmitCon>
		//     </SubContainer>
		// </Container>
		<>
			<div
				id="clickBtn"
				onClick={() => {
					window.open(
						"https://play.google.com/store/apps/details?id=com.soulgenix.android",
						"_blank",
						"noopener,noreferrer"
					);
				}}
			></div>
		</>
	);
}

const Container = styled.div`
	margin: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	color: white;
	width: 300px;
	opacity: 1;
	@media (max-width: 768px) {
		width: 350px;
		margin-top: 6vh;
	}
	font-family: "Montserrat";
`;

const SubContainer = styled.div`
	margin: 0vh 0vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	overflow: hidden;
	color: white;
	font-family: "Montserrat", sans-serif;
`;
const InputCont = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;
const InputContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 90%;
	color: red;
	margin: 2vh 0vw;
	font-family: "Montserrat", sans-serif;
`;

const CountryCode = styled.div`
	color: white;
	font-size: 25px;
	font-family: "Montserrat", sans-serif;
	margin-right: 5px;
`;

const PhoneInput = styled.input`
	width: 15px;
	height: 30px;
	text-align: center;
	margin-right: 0px;
	font-size: 24px;
	font-weight: bold; /* Added for bold text */
	background: transparent;
	color: white;
	outline: none;
	border: none;
	padding: 0px;

	::placeholder {
		color: rgba(255, 255, 255, 0.5);
	}

	@media (max-width: 768px) {
		width: 20px;
		margin-right: 0px;
		height: auto;
	}

	font-family: "Montserrat", sans-serif;
`;

const RadioContainer = styled.div`
	display: flex;
	margin-bottom: 3vh;
	align-items: center;
	font-family: "Montserrat", sans-serif;
`;

const RadioOption = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	padding: 6px;
	margin-left: 2vw;
	font-family: "Montserrat", sans-serif;
	margin: 0px 0px 0px 5px;

	label {
		margin-left: 0.5vw;
		display: flex;
		align-items: center;

		i {
			font-size: 15px;
			margin-right: 0.2vw;
		}
	}

	&:first-child {
		margin-left: 0;
	}
`;
const SubmitCon = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;
`;
const SubmitButton = styled.button`
  padding: 8px;
  background: #C6FF00;
  border-radius: 6px;
  font-weight: bold;
  font-size:16px;
  cursor: pointer;
  height:50px;
  width:"100%"
  font-family: 'Montserrat', sans-serif;

  i {
    margin-right: 2vw;
  }
   width: 220px;
  @media (max-width: 768px) {
    width: 220px;
  }
`;
const NumberInput = styled.input`
	background: transparent;
	border: none;
	width: 95%;
	outline: none;
	font-size: 24px;
	color: white;
	appearance: textfield;
	-webkit-appearance: none;
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	letter-spacing: 8px;
`;

import "./App.css";
import React from "react";
import Signup from "./components/Signup";
import Header from "./components/Header";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Careers from "./pages/Careers";
import Tribe from "./pages/Tribe";
import JoinAlphaSquad from "./pages/JoinAlphaSquad";
import SuccessAlphaSquad from "./pages/SuccessAlphaSquad";
import Insights from "./pages/Insights";
import News from "./pages/News";
import EarlyAccess from "./pages/EarlyAccess";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TnC from "./pages/TnC";
import PreRegistration from "./pages/PreRegistration";
import PreReg from "./pages/PreRegister";
import Ticket from "./pages/Ticket";

function App() {
	return (
		<Router>
			<Routes>
				{/* Pages */}
				<Route exact path="/" element={<PreReg />} />
				<Route path="/early-access" element={<EarlyAccess />} />
				<Route path="/about-us" element={<About />} />
				<Route path="/careers" element={<Careers />} />
				<Route path="/vyld-tribe" element={<Tribe />} />
				<Route path="/news" element={<News />} />
				{/* Campaigns */}
				<Route path="/join-now" element={<JoinAlphaSquad />} />
				<Route path="/success" element={<SuccessAlphaSquad />} />
				<Route path="/insights" element={<Insights />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/tnc" element={<TnC />} />
				<Route path="/pre-registration" element={<PreRegistration />} />
				<Route path="/ticket" element={<Ticket />} />
			</Routes>
		</Router>
	);
}

export default App;

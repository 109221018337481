import React, { useEffect, useState } from "react";
import "./styles.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import OtpDialog from "../../components/Otp";
import config from "../../config/app-config";

import SnowImg from "../../assets/images/snow.png";

import SignupFloatingImg1 from "../../assets/images/signup-floating-image1.png";
import SignupFloatingImg2 from "../../assets/images/signup-floating-image2.png";
import SignupFloatingImg3 from "../../assets/images/signup-floating-image3.png";
import SignupFloatingImg4 from "../../assets/images/signup-floating-image4.png";
import SignupFloatingImg10 from "../../assets/images/soft-star.png";
import JoinAlphaSquadText from "../../assets/images/join-as-vyld.png";
import SignupFloatingImg6 from "../../assets/images/lightning.png";
import SignupFloatingImg11 from "../../assets/images/white-ribbon.png";

const JoinAlphaSquad = () => {
  const BASE_URL = config.apiUrl;
  const navigate = useNavigate();
  const initialState = {
    name: "",
    email: "",
    phoneNumber: "",
    instaId: "",
    errors: {},
    loading: false,
  };
  const [formData, setFormData] = useState(initialState);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    document.title = "VYLD | Join Alpha Squad";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "An exclusive club that will help build a vibrant, new social platform."
      );
    }
  }, []);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = (val) => {
    setIsDialogOpen(false);
    setFormData({
      ...initialState,
    });
    if (val) {
      navigate("/success");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      errors: {
        ...formData.errors,
        [name]: "",
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    // Name validation
    if (!formData.name.trim()) {
      errors.name = "Please enter a valid name";
    }

    // Email validation
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Please enter a valid email id";
    }

    // Phone number validation
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = "Please enter a valid phone number";
    }

    if (Object.keys(errors).length === 0) {
      // No errors, submit the form and sending data to the server
      setFormData({
        ...formData,
      });
      await axios
        .post(BASE_URL + "/user/alphaSquad", {
          name: formData.name,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          instaId: formData.instaId,
        })
        .then(async (res) => {
          if (res.data.status) {
            await axios
              .post(BASE_URL + "/auth/otp", {
                phoneNumber: formData.phoneNumber,
              })
              .then((res) => {
                if (res.data.status) {
                  handleOpenDialog();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setFormData({ ...formData, errors });
    }
  };
  return (
    <div className="jas__container">
      <img className="snow-img" src={SnowImg} />

      <div className="jas__desc__container">
        <img
          className="jasfloating-img-7"
          src={SignupFloatingImg2}
          alt="VYLD app Gen Z user from Southeast Asia"
        />

        <img
          className="jasfloating-img-8"
          src={SignupFloatingImg1}
          alt="VYLD app Gen Z user from India"
        />
        <img
          className="jasfloating-img-8-desk"
          src={SignupFloatingImg1}
          alt="VYLD app Gen Z user from India"
        />
      </div>

      <div className="jas__title__container">
        <img
          className="jasfloating-img-4"
          src={SignupFloatingImg4}
          alt="VYLD Alpha Squad member"
        />
        <div className="jas__title__text">
          <h4 className="jas__title__subtitle"> Join the </h4>
          <img className="jafloating-img-5" src={JoinAlphaSquadText} />
          <p className="joinas__title__desc">
            Shape tomorrow’s social media today!
          </p>
        </div>
        <img className="jasfloating-img-10" src={SignupFloatingImg10} />
        <img className="jasfloating-img-6" src={SignupFloatingImg6} />
        <img
          className="jasfloating-img-3"
          src={SignupFloatingImg3}
          alt="VYLD app Gen Z user from Latin America"
        />
      </div>

      <img className="jasfloating-img-11" src={SignupFloatingImg11} />

      <div className="ja-signup-container">
        <div className="jaSignup-form__container">
          <form className="jaSignup-form" onSubmit={handleSubmit}>
            <div className="jaSignup-form__name-wrapper">
              <span
                className={
                  formData?.name?.length > 0 ? "display-none" : "ja-required"
                }
              >
                *
              </span>
              <input
                className="name-input"
                placeholder="NAME"
                type="text"
                name="name"
                required
                value={formData.name}
                onChange={handleChange}
              />
              {formData.errors.name && (
                <div className="error-text-wrapper">
                  <span className="error-text">{formData.errors.name}</span>
                </div>
              )}
            </div>

            <div className="jaSignup-form__email-wrapper">
              <span
                className={
                  formData?.email?.length > 0 ? "display-none" : "ja-required"
                }
              >
                *
              </span>
              <input
                className="email-input"
                placeholder="EMAIL ID"
                type="text"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
              />
              {formData.errors.email && (
                <div className="error-text-wrapper">
                  <span className="error-text">{formData.errors.email}</span>
                </div>
              )}
            </div>
            <div className="jaSignup-form__phone-wrapper">
              <span
                className={
                  formData?.phoneNumber?.length > 0
                    ? "display-none"
                    : "ja-required"
                }
              >
                *
              </span>
              <input
                className="phone-input"
                placeholder="PHONE"
                type="number"
                name="phoneNumber"
                required
                value={formData.phoneNumber}
                onChange={handleChange}
              />
              {formData.errors.phoneNumber && (
                <div className="error-text-wrapper">
                  <span className="error-text">
                    {formData.errors.phoneNumber}
                  </span>
                </div>
              )}
            </div>
            <div className="jaSignup-form__instaId-wrapper">
              <input
                className="instaid-input"
                placeholder="INSTA ID"
                type="text"
                name="instaId"
                value={formData.instaId}
                onChange={handleChange}
              />
            </div>
            <input
              className={
                formData.name && formData.email && formData.phoneNumber
                  ? "jas-otp-button__enabled"
                  : "jas-otp-button__disabled"
              }
              type="submit"
              value="Get OTP"
              disabled={
                formData.loading ||
                !formData.name ||
                !formData.email ||
                !formData.phoneNumber
              }
            />
          </form>
        </div>

        <p className="jas__footer-text">
          {" "}
          Invites are limited! Sign up today. Bring your creativity and get a
          chance to win goodies, early access, mystery bags, and much more!{" "}
        </p>

        {isDialogOpen && (
          <div className="backdrop">
            <div className="dialog-container">
              <OtpDialog
                phoneNumber={formData.phoneNumber}
                onClose={handleCloseDialog}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JoinAlphaSquad;

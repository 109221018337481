import React, { useState, useEffect } from "react";
import "./styles.css";
import config from "../../config/app-config";
import axios from "axios";

import SignupFloatingImg1 from "../../assets/images/signup-floating-image1.png";
import SignupFloatingImg2 from "../../assets/images/signup-floating-image2.png";
import SignupFloatingImg3 from "../../assets/images/signup-floating-image3.png";
import SignupFloatingImg4 from "../../assets/images/signup-floating-image4.png";
import WinImg from "../../assets/images/win-img.png";
import SignupFloatingImg6 from "../../assets/images/soft-star.png";

import YellowBg from "../../assets/images/win-yellow-bg.png";
import WinCupImg from "../../assets/images/win-cup-img.png";

import InsightItem1 from "../../assets/images/insight-1.png";
import InsightItem2 from "../../assets/images/insight-2.png";
import InsightItem3 from "../../assets/images/insight-3.png";

import InsightBottomMob1 from "../../assets/images/insight-bottom-img1-mob.png";
import InsightBottomDesk1 from "../../assets/images/insight-bottom-img1-desk.png";
import InsightBottom2 from "../../assets/images/insight-bottom-img2.png";

import ThunderOrange from "../../assets/images/thunder-orange.png";
import ThunderPink from "../../assets/images/thunder-pink.png";

const Insights = () => {
  const BASE_URL = config.apiUrl;

  const [formsList, setFormsList] = useState([]);


  useEffect(() => {
    document.title = 'VYLD | Participate in polls';
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Get exclusive merch and goodies when you vote in polls, take surveys, and express your ideas");
    }


    async function fetchData() {
      try {
        const response = await axios.get(BASE_URL + "/forms/list");
        setFormsList(response.data.data.list);
      } catch (error) {
        console.error("Error fetching forms:", error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="insights__container">
      <div className="insights__title__container">
        <img className="floating-img-4" src={SignupFloatingImg4} alt="VYLD Alpha Squad member" />
        <div className="insights__title__text">
          <h4 className="insights__title__subtitle"> Your opinions </h4>
          <img className="floating-img-5" src={WinImg} />
        </div>
        <img className="floating-img-3" src={SignupFloatingImg3} alt="VYLD app Gen Z user from Latin America" />
        <img className="floating-img-6" src={SignupFloatingImg6} />
      </div>

      <div className="insights__desc__container">
        <img className="floating-img-7" src={SignupFloatingImg2} alt="VYLD app Gen Z user from Southeast Asia" />
        <div className="insights__desc__text__container">
          <img className="floating-img-9" src={YellowBg} />
          <p className="insights__desc__text">
            {" "}
            Use them to build the next big social <br /> the way it’s meant to
            be{" "}
          </p>
        </div>
        <img className="floating-img-8" src={SignupFloatingImg1} alt="VYLD app Gen Z user from India" />
      </div>

      <div className="insights__win__desc">
        <img src={WinCupImg} className="insights__win__image" alt="Trophy to showcase prizes for participating in surveys and polls | PNG sticker image" />
        <img src={ThunderOrange} className="thunder-orange" />
        <p className="insights__win__desc-text">
          {" "}
          Get exclusive merch and goodies when you <br /> vote in polls, take
          surveys, and express your ideas{" "}
        </p>
        <img src={ThunderPink} className="thunder-pink" />
      </div>

      <div className="insights__list">
        <div className="insights__item">
          <img className="insights__item-img" src={InsightItem1} alt="Gen Z girl sharing her opinion with the world | PNG sticker image" />
          <p className="insights__item-text insight-1">
            {" "}
            Take a quick poll.
            <br />
            Make a big impact.{" "}
          </p>
          <a href={formsList[0]?.link} target="_blank" className="insights__item-btn">
            {" "}
            Vote now
          </a>
        </div>

        <div className="insights__item">
          <img className="insights__item-img" src={InsightItem2} alt="Bulb to show Gen Z ideas | PNG sticker image" />
          <p className="insights__item-text insight-2">
            {" "}
            Multiple thoughts. <br />
            Multiple choices. All about you.{" "}
          </p>
          <a href={formsList[1]?.link} target="_blank" className="insights__item-btn">
            {" "}
            Take the survey
          </a>
        </div>

        <div className="insights__item">
          <img className="insights__item-img" src={InsightItem3} alt="Alarm clock showing availability of time with Gen Z to sharing their opinions | PNG sticker image" />
          <p className="insights__item-text insight-3">
            {" "}
            Have a little more time and <br /> more things to say?{" "}
          </p>
          <a href={formsList[2]?.link} target="_blank" className="insights__item-btn">
            {" "}
            Dive deeper
          </a>
        </div>
      </div>

      <div className="insights__footer-content">
        <p className="insights__footer-text">
          Wanna know how we’re building the <br /> next big social for you?
        </p>

        <a className="insights__footer-btn" href="/">
          {" "}
          Learn more about VYLD{" "}
        </a>
      </div>

      <div className="insights__footer">
        <div>
          <img
            className="insights__footer_img1 insights__footer__mob-img-1"
            src={InsightBottomMob1}
          />
          <img
            className="insights__footer_img1  insights__footer__desk-img-1"
            src={InsightBottomDesk1}
          />
        </div>
        <div>
          <img className="insights__footer_img2" src={InsightBottom2} />
        </div>
      </div>
    </div>
  );
};

export default Insights;
